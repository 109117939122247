<template>
    <v-card elevation="0">
        <v-card-title>
            <v-toolbar elevation="0">
                <v-btn icon :to="{name:'StudentList'}" exact>
                    <v-icon>mdi-arrow-left</v-icon>
                </v-btn>
                <v-spacer></v-spacer>
                <v-slide-x-transition mode="in-out" v-if="!isNew">
                    <span v-show="edit">
                        <v-tooltip bottom v-if="edit">
                            <template v-slot:activator="{on, attrs}">
                                <v-btn v-on="on" v-bind="attrs" icon @click="cancelEdit">
                                    <v-icon>mdi-cancel</v-icon>
                                </v-btn>
                            </template>
                            {{$t('app.cancel')}}
                        </v-tooltip>
                    </span>
                </v-slide-x-transition>
                <v-tooltip v-if="edit" bottom>
                    <template v-slot:activator="{on, attrs}">
                        <v-btn icon v-on="on" v-bind="attrs" @click="save" :disabled="!form">
                            <v-icon>mdi-content-save</v-icon>
                        </v-btn>
                    </template>
                    {{$t('app.save')}}
                </v-tooltip>
                <v-tooltip v-else bottom>
                    <template v-slot:activator="{on, attrs}">
                        <v-btn v-on="on" v-bind="attrs" icon @click="edit = true">
                            <v-icon class="mh-wiggle">mdi-pencil</v-icon>
                        </v-btn>
                    </template>
                    {{$t('app.edit')}}
                </v-tooltip>
                <!-- <v-tooltip v-if="!isNew" bottom>
                    <template v-slot:activator="{on,attrs}">
                        <v-btn v-on="on" v-bind="attrs" icon @mouseenter="deleteIcon = 'mdi-delete-empty'" @mouseleave="deleteIcon = 'mdi-delete'" :disabled="edit">
                            <v-icon>{{deleteIcon}}</v-icon>
                        </v-btn>
                    </template>
                    {{$t('app.delete')}}
                </v-tooltip>-->
            </v-toolbar>
        </v-card-title>
        <v-card-text>
            <v-row>
                <v-col cols="4">
                    <v-form v-model="form" class="pt-4">
                        <v-text-field v-model="student.name" :label="$t('studentDetails.name.label')" dense :outlined="edit" :readonly="!edit" persistent-placeholder :rules="[(v) => (!!v && v.trim().length > 0) || $t('studentDetails.name.errors.required')]"></v-text-field>
                        <v-text-field v-model="student.studentCode" :label="$t('studentDetails.studentCode.label')" dense :outlined="edit" :readonly="!edit" placeholder></v-text-field>
                    </v-form>
                </v-col>
                <v-col cols="1" class="d-flex justify-center">
                    <v-divider vertical></v-divider>
                </v-col>
                <v-col cols="7">
                    <book-list v-model="books"></book-list>
                </v-col>
            </v-row>
        </v-card-text>
    </v-card>
</template>
<script>
import BookList from '../components/Shared/StudentBookList.vue'
export default {
    components: {BookList},
    data() {
         return {
             books: [],
             deleteIcon: 'mdi-delete',
             edit: false,
             form: null,
             srcStudent: null,
             student: {}
         }
    },
    computed: {
        isNew: {
            get() {
                return this.studentId == null
            }
        },
        studentId: {
            get() {
                return this.$route.params['id']
            }
        }
    },
    watch: {
        
    },
    methods: {
        cancelEdit() {
            this.student = {...this.srcStudent};
            this.edit = false;
        },
        loadBooks(studentId) {
            this.axios.get(`/students/${studentId}/books`).then(res => {
                const data = res.data.data
                this.books = data
            })
        },
        loadStudent(studentId) {
            this.axios.get(`/students/${studentId}`).then(res => {
                var data = res.data.data
                this.srcStudent = {...data};
                this.student = data;
            })
        },
        save() {
            if(!this.isNew){
                this.axios.put(`/students/${this.studentId}`, this.student).then(res => {
                    const data = res.data.data;
                    this.student = data
                    this.srcStudent = {...data}
                })
            }
            else {
                this.axios.post('/students', this.student).then(res => {
                    const data = res.data.data;
                    this.student = data;
                    this.srcStudent = {...data}
                })
            }
        }
    },
    created() {
        if(this.studentId) {
            this.loadStudent(this.studentId);
            this.loadBooks(this.studentId)
        }
        else {
            this.edit = true
        }
            
    }
}
</script>