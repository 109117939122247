var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-card',{attrs:{"elevation":"0"}},[_c('v-card-title',[_c('v-toolbar',{attrs:{"elevation":"0"}},[_c('v-btn',{attrs:{"icon":"","to":{name:'StudentList'},"exact":""}},[_c('v-icon',[_vm._v("mdi-arrow-left")])],1),_c('v-spacer'),(!_vm.isNew)?_c('v-slide-x-transition',{attrs:{"mode":"in-out"}},[_c('span',{directives:[{name:"show",rawName:"v-show",value:(_vm.edit),expression:"edit"}]},[(_vm.edit)?_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"icon":""},on:{"click":_vm.cancelEdit}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-cancel")])],1)]}}],null,false,3933788412)},[_vm._v(" "+_vm._s(_vm.$t('app.cancel'))+" ")]):_vm._e()],1)]):_vm._e(),(_vm.edit)?_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"icon":"","disabled":!_vm.form},on:{"click":_vm.save}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-content-save")])],1)]}}],null,false,1645321653)},[_vm._v(" "+_vm._s(_vm.$t('app.save'))+" ")]):_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"icon":""},on:{"click":function($event){_vm.edit = true}}},'v-btn',attrs,false),on),[_c('v-icon',{staticClass:"mh-wiggle"},[_vm._v("mdi-pencil")])],1)]}}])},[_vm._v(" "+_vm._s(_vm.$t('app.edit'))+" ")])],1)],1),_c('v-card-text',[_c('v-row',[_c('v-col',{attrs:{"cols":"4"}},[_c('v-form',{staticClass:"pt-4",model:{value:(_vm.form),callback:function ($$v) {_vm.form=$$v},expression:"form"}},[_c('v-text-field',{attrs:{"label":_vm.$t('studentDetails.name.label'),"dense":"","outlined":_vm.edit,"readonly":!_vm.edit,"persistent-placeholder":"","rules":[function (v) { return (!!v && v.trim().length > 0) || _vm.$t('studentDetails.name.errors.required'); }]},model:{value:(_vm.student.name),callback:function ($$v) {_vm.$set(_vm.student, "name", $$v)},expression:"student.name"}}),_c('v-text-field',{attrs:{"label":_vm.$t('studentDetails.studentCode.label'),"dense":"","outlined":_vm.edit,"readonly":!_vm.edit,"placeholder":""},model:{value:(_vm.student.studentCode),callback:function ($$v) {_vm.$set(_vm.student, "studentCode", $$v)},expression:"student.studentCode"}})],1)],1),_c('v-col',{staticClass:"d-flex justify-center",attrs:{"cols":"1"}},[_c('v-divider',{attrs:{"vertical":""}})],1),_c('v-col',{attrs:{"cols":"7"}},[_c('book-list',{model:{value:(_vm.books),callback:function ($$v) {_vm.books=$$v},expression:"books"}})],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }