<template>
    <v-data-table :items="books" :headers="headers" hide-default-footer>
        <template v-slot:body="{items}">
            <tbody name="list" is="transition-group">
                <tr v-for="item in items" :key="item.isbn" class="item-row">
                    <td>{{item.title}}</td>
                    <td>{{item.isbn}}</td>
                    <td class="text-end">
                        <div>
                            <v-btn icon @click="returnBook(item)">
                                <v-icon>mdi-arrow-down-left</v-icon>
                                <!-- {{$t('bookList.returnBookButton')}} -->
                            </v-btn>
                        </div>
                    </td>
                </tr>
            </tbody>
        </template>
        <template v-slot:item.returnAction="{item}">
            <div>
                <v-btn depressed @click="returnBook(item)">{{$t('bookList.returnBookButton')}}</v-btn>
            </div>
        </template>
    </v-data-table>
</template>
<script>
export default {
    props: {
        /** 
         * @type Array<{id, title, isbn}>
        */
        value: {
            type: Array,
            default: () => []
        }
    },
    components: {},
    data() {
         return {
             books: this.value
         }
    },
    computed: {
        headers: {
            get() {
                const headers = [];
                headers.push({text: this.$t('bookList.headers.title'), value: 'title'});
                headers.push({text: this.$t('bookList.headers.isbn'), value: 'isbn'})
                headers.push({text: '', value: 'returnAction', align: 'end'});
                return headers;
            }
        }
    },
    watch: {
        value: {
            deep:true,
            immediate: false,
            handler(v) {
                this.books = v;
                this.$emit('input', v);
            }
        }
    },
    methods: {
        returnBook(item) {
            this.axios.delete(`/books/${item.id}`).then(() => {
                this.$store.dispatch('snackbar/addSnackbar', { text: this.$t('bookList.returnedSuccessful'), closable: true, color: 'success', closeIcon: 'mdi-close'})
                var index = this.books.findIndex(x => x.id == item.id)
                var newValue = this.books.splice(index, 1);
                this.books = newValue;
                
            });
        }
    }
}
</script>
<style>
div.v-data-table__wrapper {
    overflow-x: hidden;
}
.list-enter-active{
    transition: all 0.1s;
}
.list-leave-active {
  transition: all 0.4s;
}
.list-enter {
    opacity: 0;
    transform: translateX(100%);
}
.list-leave-to {
  opacity: 0;
  transform: translateX(100%);
}
.list-move {
  transition: transform 1s;
  
}
.item-row {
  display: table-row;
  overflow: hidden;
}
</style>